import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';

function CredencialVirtual() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [index, setIndex] = useState(0);
  let [ready, setReady] = useState(false);
  let urlApi = utils.getUrlApi();
  let token = utils.getToken();
  
  let [listaCredenciais, setListaCredenciais] = useState([]);
  let [credencialSelecionada, setCredencialSelecionada] = useState(null);
  let [nomePessoacredencial, setNomePessoacredencial] = useState('');
  let [tipoSocioCredencial, setTipoSocioCredencial] = useState('');
  let [descricaoCliente, setDescricaoCliente] = useState('');
  let [graduacaoVigente, setGraduacaoVigente] = useState('');

  // crud.consultaCredenciaisCliente = function () {
  //   if (index === 0) {
  //     const request_load = {};
      
  //     request_load.crud = crud;
  //     request_load.validate = false;
  //     request_load.changed = crud.data;
  //     request_load.element = {};

  //     request_load.cfg = {
  //       url: 'credencial_cliente/consulta',
  //       method: 'GET'
  //     };
  //     request_load.complete = (res) => {
  //       if (res.data && res.data.length > 0) {
  //         setListaCredenciais(res.data);
  //         setIndex(index+1);
  //         console.log("************* consultaCredenciaisCliente *************");
  //         console.log(listaCredenciais);
  //       }
  //     };
  //     utils.call(request_load);
  //   }
  // };

  // crud.refreshCredenciaisCliente = function () {

  //   if (listaCredenciais && listaCredenciais.length > 0) {
  //     utils.prompt(
  //       {
  //         prompt: utils.lng('voce_ja_possui_credenciais_di', "Você já possui credenciais virtuais geradas na NGS, deseja gerá-las novamente mesmo assim?")
  //       }, 
  //       crud.reprocessaCredenciais, 
  //       null
  //     );
  //   } else {
  //     crud.reprocessaCredenciais();
  //   }
    
  // };

  crud.reprocessaCredenciais = function () {
    if (!ready) {
      const request_refresh = {};
      
      request_refresh.crud = crud;
      request_refresh.validate = false;
      request_refresh.changed = crud.data;
      request_refresh.element = {};

      request_refresh.cfg = {
        url: 'credencial_cliente/refresh',
        method: 'POST'
      };
      request_refresh.complete = (res) => {
        if (res.data && res.data.length > 0) {
          setListaCredenciais(res.data);
        }
        setReady(true);
        // window.location.href = '/credencial_virtual';
      };
      utils.call(request_refresh);
    }
  };

  crud.onChangeCredencial = function (evt, args, x) {
    let idCredencial = args.credenciais.id;
    if (idCredencial) {
      const request_credencial = {};
    
      request_credencial.crud = crud;
      request_credencial.validate = false;
      request_credencial.changed = crud.data;
      request_credencial.element = {};

      request_credencial.cfg = {
        url: 'CredencialCliente/'+ idCredencial,
        method: 'GET'
      };
      request_credencial.complete = (res) => {
        if (res.data && res.data.id && res.data.id > 0) {
          credencialSelecionada = res.data;
          setCredencialSelecionada(res.data);

          try {
            setNomePessoacredencial(credencialSelecionada.nome);
            setTipoSocioCredencial(credencialSelecionada.descricaoTipoSocio);
            setDescricaoCliente(credencialSelecionada.descricaoCliente);
            setGraduacaoVigente(credencialSelecionada.graduacaoVigente);
          } catch (e) {
            console.log(e);
          }
        }
      };
      utils.call(request_credencial);
    } else {
      credencialSelecionada = null;
      setCredencialSelecionada(null);
      setNomePessoacredencial('');
      setTipoSocioCredencial('');
      setDescricaoCliente('');
      setGraduacaoVigente('')
    }
    view.refresh();
  };

  crud.reprocessaCredenciais();

  let viewCfg = {
    label: utils.lng('credencial_virtual', 'Credencial Virtual'),
    name: 'credencialVirtual',
    add: false,
    print: false,
    auto: true,
    type: 'card',
    widgetVar: 'credencialVirtual',
    elements: {
      credenciais: {
        type: 'select',
        name: 'credenciais',
        field: 'credenciais',
        label: utils.lng('credenciais', "Credenciais"),
        itemLabel: 'nome',
        itemValue: 'id',
        cols: 12,
        rendered: listaCredenciais && listaCredenciais.length > 0,
        data: listaCredenciais,
        // list:{
        //   url: 'credencial_cliente/consulta' 
        // },
        change: {
          action: 'onChangeCredencial'
        }
      },
      // botoes: {
      //   style: { marginTop: '15px' },
      //   cols: 6,
      //   elements: {
      //     atualizarCredenciaisCliente: {
      //       label: utils.lng('gerar_novas_credenciais','Gerar Novas Credenciais'),
      //       type: 'button',
      //       theme: 'primary',
      //       click: {
      //         action: 'refreshCredenciaisCliente'
      //       }
      //     }
      //   }
      // }
    }
  };

  return (
    <View name="ui-credenciais" view={view}>
      <Element value={viewCfg} crud={crud} data={crud.data} view={view} ></Element>

      <div className="credencial-card ui-el-type-card" style={{ visibility: credencialSelecionada && credencialSelecionada.id ? 'visible' : 'hidden' }}>
        <div className="ui-col-12">
          
          <img className="credencial-qrcode" 
            src={ 
              urlApi+"/qrcode.png?access_token="+token+"&text="+(credencialSelecionada ? credencialSelecionada.qrCode : '')+"&width=450&height=450" 
            }
          ></img>
          <div className="ui-col-12 credencial-header">
            <span className="credencial-nome"> {nomePessoacredencial} </span>
            <span className="credencial-tipo-socio"> {tipoSocioCredencial} </span>
          </div>
          <div className="ui-col-12 credencial-footer">
            <span className="credencial-graduacao"> {graduacaoVigente} </span>
            <span className="credencial-cliente"> {descricaoCliente} </span>
          </div>
        </div>
      </div>
    </View>
  );
  
}

export default CredencialVirtual;