import React, { useState } from "react";
import Element from './element';
import utils from "./utils";
import View from './view';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Anotacao from "./anotacao";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function Pagamento() {
  let defaults = { valor_frete: 0, valor_credito: 0, valor_descontos: 0, valor_total: 0, saldo_previa: 0, saldo_spv: 0, valor_spv: 0, valor_produtos: 0, usa_spv: false, exibir_spv: true, parcela: 1 };

  let [view, setView] = useState({});
  let [pedido, setPedido] = useState(defaults);
  let [pagamento, setPagamento] = useState({ novo_valor: 0 });
  let [pagamentoEditando, setPagamentoEditando] = useState({ novo_valor: 0 });
  let [bandeira, setBandeira] = useState({});
  let [parcela, setParcela] = useState(1);
  let [pagamentos, setPagamentos] = useState({});
  let [meusPagamentos, setMeusPagamentos] = useState({});
  let [bandeiras, setBandeiras] = useState([]);
  let [parcelas, setParcelas] = useState([]);
  let [saldos, setSaldos] = useState([]);
  let [crud, setCrud] = useState({ data: defaults, changed: defaults, requireds: {} });
  let [ready, setReady] = useState(false);
  let [usouSPV, setUsouSPV] = useState(false);
  let [showDialog, setShowDialog] = useState(false);
  let [index, setIndex] = useState(0);
  let [valorPagamento, setValorPagamento] = useState(0);
  let idPedido = utils.getPath(3);

  let tipoPagamento = localStorage.getItem('tipo_metodo_pgto');

  if (!tipoPagamento) {
    tipoPagamento = 'expresso';
  }

  if (!idPedido) {
    idPedido = 0;
  }

  window.processo_atual = 73;


  const onChangeValor = function (event) {
    let val = event.target.value;

    pagamentoEditando.novo_valor = val;
    pagamentoEditando.editado = true;
    setValorPagamento(val);
  }

  crud.refresh = function () {
    setCrud(crud);
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  const carregarPagamentos = function (p) {
    utils.execute(crud, 'listarCondicaoPagamento?id_pedido=' + p.id_pedido, null, function (res) {
      pagamentos = {};
      const mp = {};

      for (const ri in res.data) {
        const ro = res.data[ri];

        if (ro.valor_pagamento > 0) {
          mp[ro.codigo] = ro;

          if (tipoPagamento === 'expresso') {
            editarPagamento(ro);
          }
        }
        pagamentos[ro.codigo] = ro;
      }
      setPagamentos(pagamentos);
      setMeusPagamentos(mp);
    });
  }

  const carregarSaldos = function (p) {
    utils.execute(crud, 'consultar_saldo_cliente?id_cliente=' + p.id_cliente, null, function (res) {
      if (res.data) {
        saldos = [];
        setSaldos(res.data);

        for (const i in res.data) {
          const s = res.data[i];
          const k = ('saldo_' + s.tipo).toLowerCase();
          p[k] = s.saldo;
        }
      }
    });
  }

  const loadPedidoByHash = function (hash) {
    let _ks_ = {};
    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.element = {};

    _ks_.complete = function (res) {
      idPedido = res && res.data ? res.data.id : 0;
      carregarPedido();
    };

    _ks_.cfg = {
      url: 'cart/loadPedidoVendaByHash/' + hash,
      method: 'GET'
    };
    utils.call(_ks_);
  }

  const carregarPedido = function () {
    utils.execute(crud, 'encontrarPedido?id_pedido=' + idPedido, null, function (res) {
      for (const i in res.data) {
        const p = res.data[i];

        p.parcela = 1;

        pedido.metodo_pgto = tipoPagamento;
        pedido = p;
        setPedido(p);
        carregarSaldos(p);
        carregarPagamentos(p);
      }
    });

    utils.execute(crud, 'HubBandeira', ['id', 'descricao', 'cor', 'icone'], function (res) {
      const bandeiras = res.data;

      setBandeiras(bandeiras);

      if (bandeiras.length > 0) {
        selecionarBandeira(bandeiras[0]);
      }
    });
  }

  if (!ready) {
    setReady(true);
    if (idPedido.length > 20) {
      loadPedidoByHash(idPedido);
    } else {
      carregarPedido();
    }
  }

  const confirmDialog = function () {
    if (pagamentoEditando.novo_valor > 0) {
      let _ks_ = {};

      pagamentoEditando.valor_novo = pagamentoEditando.novo_valor;

      _ks_.global = true;
      _ks_.crud = crud;
      _ks_.changed = pagamentoEditando;
      _ks_.element = {};

      _ks_.complete = function (res) {
        window.location.reload();
      };

      _ks_.cfg = {
        url: 'cart/changePayment',
        method: 'POST'
      };
      utils.call(_ks_);
    } else {
      closeDialog();
    }
  }
  const changeParcela = function (p) {
    let _ks_ = {};

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = p;
    _ks_.element = {};

    _ks_.complete = carregarPedido;

    _ks_.cfg = {
      url: 'cart/changePayment',
      alert: false,
      method: 'POST'
    };
    utils.call(_ks_);
  }

  const closeDialog = function () {
    showDialog = false;
    setShowDialog(showDialog);
  }

  const editarValor = function () {
    if (pagamento.confirmado) {
      return;
    }
    pagamento.novo_valor = 0;
    pagamentoEditando = pagamento;
    pagamentoEditando.id_condicao_pagamento = pagamento.id;

    showDialog = true;
    setPagamentoEditando(pagamento);
    setShowDialog(true);
  }

  const closePagamento = function (p) {
    setPagamento({});
    setPagamentoEditando({});
  };

  const editarPagamento = function (p) {
    p.metodo_pgto = tipoPagamento;

    let _ks_ = {};
    let url = 'listarParcelas?id_condicao=' + p.id + '&id_pedido=' + pedido.id_pedido;

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = {};
    _ks_.element = {};

    _ks_.complete = function (res) {
      setParcelas(res.data);
      parcelas = res.data;

      pagamento = p;

      setValorPagamento(p.valor_pagamento);
      setPagamento(p);

      let np = p.parcela ? p.parcela : 1;

      pedido.id_condicao_pagamento = p.id;
      pedido.parcela = np;
      pedido.metodo_pgto = tipoPagamento;

      parcela = np;
      setParcela(np);

      crud.data = p;
      crud.changed = p;
      crud.changed.parcela = np;
      crud.changed.id_condicao_pagamento = p.id;

      if (bandeira) {
        crud.changed.bandeira = bandeira.id;
      }

      setIndex(index + 1);

      setPagamento(p);
    };

    _ks_.cfg = {
      url: url,
      method: 'GET'
    };
    utils.call(_ks_);
  }

  const selecionarBandeira = function (b) {
    setBandeira(b);

    crud.changed.bandeira = b.id;
    setIndex(index + 1);
  }

  const selecionarParcela = function (b) {
    changeParcela({ id_condicao_pagamento: pagamento.id, parcela: b });
    setParcela(b);
    crud.changed.parcela = b;
    setIndex(index + 1);
  }

  const labelParcela = function (pgto, i) {
    let s = i.parcela + 'x' + (i.valor_acrescimo > 0 || i.percentual_acrescimo > 0 ? '' : '') + utils.lng('de', ' de ') + (pedido.exibir_centavos ? utils.money(i.valor_parcela) : utils.moneyCents(i.valor_parcela));
    
    if (i.percentual_desconto > 0) {
      s = s + '  (' + i.percentual_desconto + '% ' + utils.lng('de_desconto', 'de desconto') + ')';
    }

    if (pedido.valor_spv > 0) {
      let vlrpv = pedido.valor_produtos + pedido.valor_spv;
      let pspv = (pedido.valor_spv * 100) / vlrpv;
      let vtpg = i.valor_parcela * i.parcela;
      let vspv = (vtpg * pspv) / 100;
      let vtpr = vtpg - vspv;

      s += '<br>';
      
      s += "<div class='ui-parc-tot-spv'> " + 
        utils.lng('valor_produtos', 'Valor Produtos:') + (pedido.exibir_centavos ? utils.money(vtpr.toFixed(2)) : utils.moneyCents(vtpr.toFixed(2))) + " -" + 
        utils.lng('spv', 'Valor SPV:  ') + (pedido.exibir_centavos ? utils.money(vspv.toFixed(2)) : utils.moneyCents(vspv.toFixed(2))) +
      "</div>";
    }
    return s;
  }

  const cssBandeira = function (b) {
    return { color: b.cor };
  }

  const cartao = {
    name: 'cartao',
    elements: {
      numero_cartao: { type: 'tel', label: utils.lng('numero', 'Número'), mask: '9999.9999.9999.9999', required: true },
      nome_cartao: { type: 'text', label: utils.lng('nome_titular', 'Nome Titular'), required: true },
      mes_cartao: { type: 'tel', label: utils.lng('mes_vencimento', 'Mês vencimento'), mask: '99', numeric: true, cols: 6, required: true },
      ano_cartao: { type: 'tel', label: utils.lng('ano_vencimento', 'Ano vencimento'), mask: '9999', numeric: true, cols: 6, required: true },
      codigo_cartao: { type: 'tel', label: utils.lng('codigo_seguranca', 'Código de segurança'), mask: '9999', numeric: true, cols: 4, required: true },
      cpf_cartao: { type: 'text', mask: '999.999.999-99', label: utils.lng('cpf_titular', 'CPF Titular'), cols: 8, required: true }
    }
  };

  const chave = {
    name: 'chave',
    elements: {
      chave_operacao: { type: 'text', label: utils.lng('chave_operacao', 'Chave Operação'), required: true }
    }
  };


  const finalizar = {
    name: 'finalizar',
    elements: {
      numero: {
        type: 'button', label: utils.lng('finalizar', 'Finalizar'), theme: 'default',
        click: {
          url: 'cart/pay',
          method: 'POST',
          redirect: '/carrinho/pedido/' + pedido.id_pedido
        }
      }
    }
  };


  const confirmar = {
    name: 'confirmar',
    elements: {
      comprovantes: {
        space: 12,
        type: 'upload',
        upload: true,
        field: 'comprovantes',
        filesLimit: 5,
        multiple: true,
        rendered: '${@this.comprovante_obrigatorio === true}',
        showLabel: false,
        label: 'Anexar comprovante'
      },
      acoesPgto: {
        space: 12,
        style: { float: 'right', paddingTop: '15px' },
        layout: 'inline',
        elements: {
          fechar: {
            type: 'button', label: utils.lng('fechar', 'Fechar'), theme: 'inherit',
            click: closePagamento
          },
          alterar_valor: {
            type: 'button', label: utils.lng('alterar_valor', 'Alterar valor'),
            rendered: '${!@this.confirmado && !@this.novo && @this.metodo_pgto !== "expresso" }',
            click: editarValor
          },
          remover: {
            type: 'button', label: utils.lng('remover', 'Remover'), theme: 'secondary',
            rendered: '${@this.novo === true && @this.metodo_pgto !== "expresso"}',
            click: function () {
              delete meusPagamentos[pagamento.codigo];

              closePagamento();
            }
          },
          remover: {
            type: 'button', label: utils.lng('remover', 'Remover'), theme: 'secondary',
            rendered: '${!@this.confirmado && !@this.novo && @this.metodo_pgto !== "expresso"}',
            click: {
              url: 'cart/delPayment/{id}',
              method: 'DELETE',
              redirect: true,
              validate: false,
              send: false
            }
          },
          confirmar: {
            type: 'button', label: utils.lng('confirmar', 'Confirmar'),
            style: { marginLeft: '15px' },
            rendered: '${!@this.confirmado}',
            click: {
              url: 'cart/pay',
              method: 'POST'
            }
          },
          redirecionarPagto: {
            type: 'button', label: utils.lng('redirecionar_pagto', 'Redirecionar Pagamento'),
            style: { marginLeft: '15px' },
            rendered: '${@this.redirecionar_pagto}',
            click: {
              url: 'cart/redirectPayment/' + pedido.id_pedido,
              method: 'POST'
            }
          }
        }
      }
    }
  };

  const confirmarTudo = {
    name: 'confirmar',
    theme: 'success',
    type: 'button', label: utils.lng('confirmar', 'Confirmar'),
    style: { marginLeft: '15px', textAlign: 'right', float: 'right' },
    click: {
      url: 'cart/pay',
      method: 'POST'
    }
  };

  const getListaPagamentos = function () {
    const aux = {};

    for (const cod in meusPagamentos) {
      aux[cod] = meusPagamentos[cod];
    }

    return aux;
  };

  crud.adicionarPagamento = function (a, b) {
    if (!b.data.formaPgto) {
      return;
    }

    const pgc = b.data.formaPgto.constante;

    if (pagamentos[pgc].valor_pagamento > 0) {
      window.dialog.alert('Opção de pagamento já adicionada');
      return;
    }

    selecionarPagamento(pagamentos[pgc], false);
  }

  const selecionarMetodoPgto = function (m) {

    selecionarPagamento({ alterar_metodo: true, metodo_pgto: m.value }, true, function (res) {
      if (!res.error) {
        localStorage.setItem('tipo_metodo_pgto', m.value);
        window.location.reload();
      }
    });
  }

  const selecionarPagamento = function (pg, unico, complete) {
    let _ks_ = {};
    let _ts_ = {};
    let pago = 0.05;

    if (_ts_.alterar_metodo) {
      _ts_.metodo_pgto = pg.metodo_pgto;
    } else {
      pagamento = pg;
      _ts_.metodo_pgto = tipoPagamento;
    }

    for (const i in pagamento) {
      _ts_[i] = pagamento[i];
    }

    for (const i in pagamentos) {
      let pg = pagamentos[i];
      let vlr = parseFloat(pg.valor_pagamento);

      _ts_[i] = pg;


      if (vlr > 0) {
        pago = pago + vlr;
      }
    }

    if (pago >= pedido.valor_total && !unico) {
      window.dialog.alert('Valor já esta completo');
      return;
    }

    _ts_.parcela = 1;
    _ts_.id_condicao_pagamento = _ts_.id;
    _ts_.valor_pagamento = 0;

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = _ts_;
    _ks_.element = {};

    _ks_.complete = function (res) {
      if (complete) {
        complete.call(this, res);
      } else
        if (!res.error) {
          window.location.reload();
        }
    };

    _ks_.cfg = {
      url: 'cart/changePayment',
      alert: false,
      method: 'POST'
    };
    utils.call(_ks_);
  }

  const alternaPgto = {
    cols: 12,
    layout: 'grid',
    elements: {
      aga: {
        field: 'formaPgto',
        type: 'select',
        itemLabel: 'nome',
        itemValue: 'codigo',
        label: utils.lng('forma_pagamento', 'Selecione uma forma de pagamento'),
        cols: 8,
        data: pagamentos
      },
      agb: {
        field: 'btn_add_pgto',
        label: utils.lng('adicionar', 'Adicionar'),
        type: 'button',
        cols: 4,
        click: {
          action: 'adicionarPagamento'
        }
      }
    }
  };

  const isPagamento = function (p, s) {
    if (p && p.codigo) {
      if (p.codigo.indexOf(s) >= 0) {
        return true;
      }
    }
    return false;
  };

  const salvarOrcamento = {
    name: 'salvar_orcamento',
    styleClass: 'ui-btn-orcamento',
    elements: {
      salvar_orcamento: {
        theme: 'success',
        type: 'button', label: utils.lng('salvar_orcamento', 'Salvar Como Orçamento'),
        click: {
          prompt: utils.lng('salvar_pedido_como_orcamento', 'Salvar o pedido como orçamento?'),
          url: 'cart/budget',
          method: 'POST'
        }
      }
    }
  };

  const BlocoPagar = function () {
    return (
      <>
        {pagamento.codigo &&
          <>
            {tipoPagamento !== 'expresso' &&
              <div className="ui-pgto-descricao">
                {pagamento.nome}
              </div>
            }
            <div className="ui-pgto-confirmacao ui-grid">
              <>
                {isPagamento(pagamento, 'CARTAO') &&
                  <>
                    <div className="ui-col ui-col-12  ui-padding">
                      <div className="ui-bandeiras">
                        {bandeiras.map((i, index) => (
                          <span onClick={() => { selecionarBandeira(i) }} id={'bandeira_' + (i.id)} className="ui-bandeira">
                            <i className={'ui-check-icon ' + (i.id === bandeira.id ? 'far fa-dot-circle' : 'far fa-circle')} ></i>
                            <i style={cssBandeira(i)} className={i.icone} ></i>
                          </span>
                        ))
                        }
                      </div>
                    </div>
                  </>
                }

                <div className="ui-grid-row ui-row">
                  <div className="ui-col ui-col-12 ui-padding">
                    <div className="ui-grid">
                      <div className="ui-col ui-parcelas">
                        {parcelas.map((i, index) => (
                          <div id={"parcela_" + i.parcela} onClick={() => { selecionarParcela(i.parcela) }}
                            className={'ui-parcela ui-pointer ui-col ui-col-12 ' + (index % 2 === 0 ? 'ui-row-event' : 'ui-row-odd')}>
                            <div className="ui-grid">
                              {pagamento.codigo === 'BOLETO' &&
                                <table>
                                  <tr>
                                    <td>
                                      <i className="fa fa-barcode ui-big-icon" ></i>
                                    </td>
                                    <td>
                                      <i style={{ color: i.color }} className={'ui-check-icon ' + (i.parcela === parcela ? 'fas fa-check-circle' : 'far fa-circle')} ></i>
                                      <span dangerouslySetInnerHTML={{ __html: labelParcela(pagamento, i) }} ></span>
                                      <div style={{ paddingLeft: '10px' }}>
                                        <b>{utils.lng('info_boleto', '* Pagável em qualquer banco.')}</b><br />
                                        {utils.lng('warn_boleto', '* IMPORTANTE: A compensação bancária pode levar até 2 dias úteis.')}
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              }
                              {pagamento.codigo !== 'BOLETO' &&
                                <div className="ui-col ui-padding">
                                  <i style={{ color: i.color }} className={'ui-check-icon ' + (i.parcela === parcela ? 'fas fa-check-circle' : 'far fa-circle')} ></i>
                                  <span dangerouslySetInnerHTML={{ __html: labelParcela(pagamento, i) }} ></span>
                                </div>
                              }
                            </div>
                          </div>
                        ))
                        }
                      </div>
                      {isPagamento(pagamento, 'CARTAO') &&
                        <div className="ui-col ui-col-8 ui-form-pgto ui-pgto-cartao  ui-border-top ui-border-left ui-white ui-padding">
                          <Element value={cartao} crud={crud} data={pagamento} view={view} ></Element>
                        </div>
                      }
                      {isPagamento(pagamento, 'TEF') &&
                        <div className="ui-col ui-col-8 ui-form-pgto ui-pgto-cartao  ui-border-top ui-border-left ui-white ui-padding">
                          <Element value={chave} crud={crud} data={pagamento} view={view} ></Element>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="ui-col ui-col-12 ui-border-top ui-white ui-padding">
                  <Element value={confirmar} crud={crud} data={pagamento} view={view} ></Element>
                </div>
              </>
            </div>
          </>
        }
      </>);
  }

  return (
    <View name="carrinho">
      {pedido.id_pedido > 0 &&
        <div className="ui-pagamento">
          <Dialog
            open={showDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{utils.lng('edit_valor', 'Editar valor')}</DialogTitle>
            <DialogContent>
              <div id="novo_valor" className="ui-opt-valor" style={{ marginTop: '15px' }}>
                <CurrencyTextField autoComplete="new-password"
                  defaultValue=''
                  variant="outlined"
                  currencySymbol={utils.currencySymbol()}
                  decimalCharacter={','}
                  digitGroupSeparator={'.'}
                  value={pagamentoEditando.novo_valor}
                  onChange={(event, vl) => onChangeValor({ target: { value: vl } })} >
                </CurrencyTextField>
              </div>
            </DialogContent>
            <DialogActions>
              <Button id="btnCancelarEdicao" onClick={closeDialog} variant="outlined" color="primary">{utils.lng('cancelar', 'CANCELAR')}</Button>
              <Button id="btnConfirmarEdicao" onClick={confirmDialog} variant="outlined" color="primary">{utils.lng('confirmar', 'Confirmar')}</Button>
            </DialogActions>
          </Dialog>
          <div className="ui-topo ui-shadow ui-border ui-white">
            <div className="ui-col ui-col-2 ui-padding ui-color-green ui-bold">
              {utils.lng('lbl_pedido_aberto', 'PEDIDO EM ABERTO')}
            </div>
            <div className="ui-col ui-col-2 ui-border-left ui-padding ui-color-red ui-bold ui-item-select">
              {utils.lng('lbl_pagamento', 'PAGAMENTO')}
            </div>
          </div>
          <div className="ui-content">
            <div className="ui-resumo-pedido-label">
              {utils.lng('resumo_pedido', 'Resumo do pedido')}
            </div>
            <div styleClass="ui-finalizar">
              <Element value={salvarOrcamento} crud={crud} data={pedido} view={view} ></Element>
            </div>
            <div className="ui-shadow ui-white ui-grid">
              <div className="ui-col ui-col-2 ui-padding">
                <div className="ui-small-label">
                  {utils.lng('valor', 'Valor dos itens')}
                </div>
                <div id="pedido_valor_produtos" className="ui-value ui-bold ui-color-blue">
                  {pedido.exibir_centavos ? utils.money(pedido.valor_produtos) : utils.moneyCents(pedido.valor_produtos)}
                </div>
              </div>

              {pedido.exibir_custo_dolar &&
                <>
                  <div className="ui-col ui-col-12 ui-padding">
                    <div className="ui-small-label">
                      {utils.lng('dolar', 'Dolar')}
                    </div>
                    <div id="valor_dolar" className="ui-value ui-bold ui-color-blue">
                      {utils.customMoney('$', pedido.valor_custo)}
                    </div>
                  </div>
                </>
              }

              {pedido.exibir_spv &&
                <>
                  <div className="ui-col ui-col-1 ui-border-left ui-padding">
                    <div className="ui-small-label">
                      {utils.lng('spv', 'SPV')}
                    </div>
                    <div id="pedido_valor_spv" className="ui-value ui-bold ui-color-blue">
                      {pedido.exibir_centavos ? utils.money(pedido.valor_spv) : utils.moneyCents(pedido.valor_spv)}
                    </div>
                  </div>
                  <div className="ui-col ui-col-1 ui-search-right" style={{ verticalAlign: 'top' }}>
                    <i
                      className="fa fa-question ui-padding"
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        window.dialog.alert(utils.lng('msg_creditos_anteriores', 'Créditos anteriores a 01/03/2021 não estão disponíveis na NGS. <br>Para utilização destes será necessário entrar em contato com a equipe do financeiro da Nipponflex.'));
                      }}
                    ></i>
                  </div>
                </>
              }
              <div className="ui-col ui-col-2 ui-border-left ui-padding">
                <div className="ui-small-label">
                  {utils.lng('frete', 'Frete')}
                </div>
                <div id="pedido_valor_frete" className="ui-value ui-bold ui-color-green">
                  {pedido.valor_frete <= 0 &&
                    <>
                      {utils.lng('gratis', 'Grátis')}
                    </>
                  }
                  {pedido.valor_frete > 0 &&
                    <>
                      {pedido.exibir_centavos ? utils.money(pedido.valor_frete) : utils.moneyCents(pedido.valor_frete)}
                    </>
                  }
                </div>
              </div>
              <div className="ui-col ui-col-4 ui-border-left ui-padding">
                <div className="ui-small-label">
                  {utils.lng('valor_pedido', 'Valor do pedido')}
                </div>
                <div id="pedido_valor_total" className="ui-value ui-bold ui-color-green ">
                  {pedido.exibir_centavos ? utils.money(pedido.valor_pedido) : utils.moneyCents(pedido.valor_pedido)}
                </div>
              </div>
              <div className="ui-col ui-col-2 ui-border-left ui-padding">
                <div className="ui-small-label">
                  {utils.lng('descontos', 'Descontos')}
                </div>
                <div id="pedido_valor_descontos" className="ui-value ui-bold ui-color-red">
                  {pedido.exibir_centavos ? utils.money(pedido.valor_descontos) : utils.moneyCents(pedido.valor_descontos)}
                </div>
              </div>

            </div>
            {saldos.map((s, index) => (
              <>
                {s.saldo > 0 &&
                  <>
                    <div className="ui-big-label">
                      Créditos [{s.descricao}]
                    </div>
                    <div className="ui-shadow ui-white ui-grid ui-small ui-middle ui-small-button">
                      <div id={'pedido_' + s.key} className="ui-col ui-col-12 ui-padding ui-bold">
                        Você possui  <span className="ui-bold ui-color-green ">
                          {utils.money(s.saldo)}
                        </span> em créditos {s.descricao}.
                      </div>
                    </div>
                  </>
                }
              </>
            ))
            }
            <div id="adicionar_anotacao" className="ui-big-label">
              {utils.lng('selecionar_pagamento_metod', 'Selecionar forma de pagamento')}
              {pedido.id_pedido > 0 &&
                <span class="anotacao-pagamento">
                  <Anotacao label={utils.lng('add_anotacao', "Adicionar anotação")}
                    list={false} id={pedido.id_pedido} entidade='HubPedidoVenda' view={view}></Anotacao>
                </span>
              }
            </div>
            <div className="ui-shadow ui-white ui-grid ui-grid-pgtos ui-grid-metodo-pgtos" style={{ marginBottom: '15px' }}>
              {[{ label: utils.lng('expresso', 'Pagamento Único'), value: 'expresso' }, { label: utils.lng('multiplos_pagamentos', 'Pagamento Multiplo'), value: 'multiplo' }].map((i, index) => (
                <div id={'metodoPagamento_' + index} onClick={() => { selecionarMetodoPgto(i) }} className={'ui-click ui-col ui-col-6 ' + (i.value === tipoPagamento ? ' ui-tab-sel' : '')} style={{ padding: '10px' }}>
                  {i.label}
                </div>
              ))
              }
            </div>
            {tipoPagamento === 'expresso' &&
              <>
                <div id='escolha_pgto_expresso' className="ui-shadow ui-white ui-grid ui-grid-pgtos ui-escolha-pgto  ui-escolha-pgto-uk" style={{ marginBottom: '15px' }}>
                  {Object.entries(pagamentos).map((i, index) => (
                    <div onClick={() => { selecionarPagamento(i[1], true) }} id={'pgto_' + (i[1].id)} className="ui-click ui-frm-uk-pgto" style={{ padding: '10px' }}>
                      <i className={'ui-check-icon ' + (i[1].valor_pagamento > 0 ? 'far fa-dot-circle' : 'far fa-circle')} ></i>
                      {i[1].nome}
                    </div>
                  ))
                  }
                </div>
                <BlocoPagar></BlocoPagar>
              </>
            }
            {tipoPagamento !== 'expresso' &&
              <>
                <div className="ui-shadow ui-white ui-grid ui-grid-pgtos ui-escolha-pgto">
                  <div className="ui-total-pagar ui-col ui-col-4 ui-padding">
                    <div className="ui-small-label">
                      {utils.lng('total_liquido', 'Total líquido a pagar')}
                    </div>
                    <div id="pedido_total_liquido" className="ui-value ui-bold ">
                      <span className="ui-color-green ">
                        {pedido.exibir_centavos ? utils.money(pedido.valor_total) : utils.moneyCents(pedido.valor_total)}
                      </span>
                    </div>
                  </div>
                  <div id="seleciona_pagamento" className="ui-col ui-col-8 ui-padding">
                    <Element crud={crud} value={alternaPgto} data={pedido} view={view} ></Element>
                  </div>
                </div>
                <div id="lista_pagamento" className="ui-grid ui-grid-pgtos ui-grid-meus-pgtos">
                  {Object.entries(getListaPagamentos()).map((el, i) => (
                    <>
                      <>
                        <div className="ui-grid-row ui-row">
                          <div className={'ui-col ui-item-pgto ui-item-pgto-' + i}>
                            {i + 1}
                          </div>
                          <div className="ui-col ui-pgto-valor ui-padding ui-click" onClick={() => { editarPagamento(el[1]) }}>
                            <div className="ui-small-label">
                              {el[1].confirmado &&
                                <>Valor a pagar</>
                              }
                            </div>

                            {!el[1].confirmado &&
                              <div className="ui-value ">
                                <i class="fa fa-edit"></i> {utils.lng('editar', 'Editar')}
                              </div>
                            }
                            {el[1].confirmado &&
                              <div className="ui-value ui-bold ">
                                {pedido.exibir_centavos ? utils.money(el[1].valor_pagamento) : utils.moneyCents(el[1].valor_pagamento)}
                              </div>
                            }
                          </div>
                          <div className="ui-col ui-pgto-detalhe ui-padding ui-bold ui-big ui-click" onClick={() => { editarPagamento(el[1]) }}>
                            {el[1].nome} <span className="ui-info-pgto-cart"><i class="far fa-hand-point-right"></i> {utils.lng('configurar_forma_pagamento', 'Clique para configurar a condição de pagamento')} </span>
                          </div>
                          <div className={'ui-col ui-pgto-status ui-padding ui-bold ui-big ui-click ' + (el[1].confirmado ? 'ui-pgto-status-confirmado' : '')}
                            onClick={() => { editarPagamento(el[1]) }}>
                            <i className={el[1].confirmado ? 'fa fa-check' : 'fa fa-ban'}></i>
                          </div>
                        </div>
                      </>
                    </>
                  ))
                  }
                </div>
                <BlocoPagar></BlocoPagar>
              </>
            }
          </div>
        </div>
      }
    </View>
  );
}

export default Pagamento;