import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Element from './element';
import utils from "./utils";
import CustomDialog from './custom_dialog';
import View from './view';

function Login() {
  const [view, setView] = useState({});
  const [crud, setCrud] = useState({ changed: {}, data: {} });
  const [menus, setMenus] = useState([]);
  const [esqueceu, setEsqueceu] = useState(false);
  let [message, setMessage] = useState({});
  let [title, setTitle] = useState({});
  let [actions, setActions] = useState([]);
  let [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    (function (w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + (Date.now() / 60000 | 0);

      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn.bitrix24.com/b12868253/crm/site_button/loader_3_j32rz8.js');

  });

  const open = function (args, data) {
    console.log(args);
  };

  let [user, setUser] = useState({});
  let tabs = [];

  let jsonUser = localStorage.getItem('user');

  if (jsonUser) {
    user = JSON.parse(jsonUser);
    window.user = user;
  }

  const handleClose = function () {
    setDialogOpen(false);
  }


  view.alert = function (message, title, actions) {
    if (!actions) {
      actions = {};
    }
    if (!title) {
      title = 'Atenção';
    }
    setTitle(title);
    setMessage(message);
    setActions(actions);
    setDialogOpen(true);
  }

  window.view = view;

  let login = {
    styleClass: 'ui-login-panel',
    elements: {
      left: {
        cols: 3,
        elements: {
          logo_ngs: { type: 'img', value: 'static/img/logo_ngs.png', center: true }
        }
      },
      center: {
        cols: 6,
        elements: {
          logar: {
            type: "form",
            elements: {
              logo: { type: 'img', value: 'static/img/logo.svg', center: true },
              username: { type: 'text', label: utils.lng('cod_mundial_email', 'Cód. Mundial ou e-mail'), name: 'username', title: 'Informe seu login' },
              password: {
                type: 'password', label: 'Senha', name: 'password', title: 'Digite sua senha',
                enter: {
                  url: 'login',
                  store: 'user',
                  define: 'user',
                  alert: true,
                  redirect: '${user.url ? user.url : "/home" }',
                  method: 'POST'
                }
              },
              btnLogar: {
                type: 'button', label: 'ENTRAR', icon: '', theme: 'secondary', styleClass: 'ui-btn-login',
                click: {
                  url: 'login',
                  store: 'user',
                  define: 'user',
                  alert: true,
                  redirect: '${user.url ? user.url : "/home" }',
                  method: 'POST'
                }
              },
              esqueci_senha: { type: 'link', value: 'esqueci a senha', href: 'esqueci_senha', center: true }
            }
          }
        }
      }, right: {
        cols: 3
      }
    }
  };
  return (
    <View full={true}>
      <Element crud={crud} value={login} data={user} user={user} />
    </View>
  );
}

export default Login;