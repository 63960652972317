import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect, Fragment } from "react";
import utils from './utils';

const Complete = ({ value, required, disabled, user, data, crud, clientId }) => {
    let element = utils.decode(value, crud, data);
    let view = window.view;
    let name = element.name;

    crud.elements[name] = value;

    let x = utils.resolve(element, data);

    if (!x) {
        x = '';
    }

    let [loading, setLoading] = useState(false);
    let [ready, setReady] = useState(false);
    let [options, setOptions] = useState([]);
    let [query, setQuery] = useState(value.query);
    let [last, setLast] = useState(null);

    let variant = value.variant;

    const itemLabel = value.itemLabel ? value.itemLabel : 'descricao';
    const itemAvatar = value.itemAvatar ? value.itemAvatar : 'avatar';
    const itemValue = value.itemValue ? value.itemValue : 'id';

    const search = function (old) {
        let _ks_ = old ? old : {};

        _ks_.crud = crud;
        _ks_.view = view;
        _ks_.changed = { query: query, size: value.size ? value.size : 10 };
        _ks_.element = value;

        if (value.fields) {
            _ks_.changed.fields = value.fields.join(',');
        }

        _ks_.complete = function (res) {
            loading = false;
            setLoading(false);

            const opts = [];

            for (const i in res.data) {
                const op = {};
                const oi = res.data[i];


                op.label = oi[itemLabel];
                op.value = oi[itemValue];
                op.avatar = oi[itemAvatar];

                opts.push(op);
            }
            setOptions(opts);

            if (last) {
                let a = last;

                last = null;
                setLast(null);

                search(a);
            }
        };
        _ks_.cfg = value.list;

        if (loading) {
            //last = _ks_;
            //setLast(last);

            //return;
        }

        loading = true;
        setLoading(true);

        utils.call(_ks_);
    };

    const onChange = function (val) {
        if (!val) {
            value.setValue(null);
        } else {
            value.setValue(val.value);
        }
    };

    const onInputChange = function (value) {

        query = value;
        setQuery(value);

        search();
    };

    const columns = [];

    if (value.columns) {
        for (const i in value.columns) {
            const col = value.columns[i];

            if (!col.name) {
                col.name = i;
            }

            if (!col.field) {
                col.name = i;
            }

            if (!col.id) {
                col.id = col.name;
            }

            if (!col.type) {
                col.type = 'span';
            }

            if (col.attrs) {
                for (const key in col.attrs) {
                    col[key] = col.attrs[key];
                }
            }
            if (col.visible !== false) {
                col.name = i;
                columns.push(col);
            }
        }
    }

    useEffect(() => {
        if (!ready) {
            setReady(true);

            if (value.auto !== false) {
                search();
            }
        }
    }, []);

    let icon = element.inputIcon;

    if (!icon) {
        icon = 'fa fa-angle-down';
    }

    let empty = element.empty;

    if (!empty) {
        empty = utils.lng('nada_encontrado', 'Nada encontrado');
    }

    let getOptionLabelHtml = function (option) {
        return (
            <Fragment>
                {option.avatar &&
                    <img className='ui-complete-avatar' src={option.avatar}></img>}
                <span>{option.label}</span>
            </Fragment>
        );
    }

    return (
        <Autocomplete
            options={options}
            noOptionsText={empty}
            onInputChange={(event, newInputValue) => {
                onInputChange(newInputValue);
            }}
            popupIcon={
                <i className={icon}></i>
            }
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={option => getOptionLabelHtml(option)}
            renderInput={(params) => <TextField {...params} label={value.label} variant={variant} />}
        />
    )
};

export default Complete;